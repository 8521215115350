<template>
  <LayoutPrevNextComponent
    :prev-path="routeObject(next?.slug)"
    :next-path="routeObject(prev?.slug)"
    :prev-label="next?.title"
    :next-label="prev?.title"
  />
</template>

<script lang="ts" setup>
const props = defineProps({
  prev: Object,
  next: Object
})

const nuxtApp = useNuxtApp()
const routeName = computed(() => nuxtApp.$getRouteBaseName())
const routeObject = (slug: string) => {
  return {
    name: routeName.value,
    params: { slug }
  }
}
</script>
